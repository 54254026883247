import { Car, PersonSimpleWalk, SealCheck } from "@phosphor-icons/react";
import { useQuiz } from "../../hooks/useQuiz"
import { Link } from "react-router-dom";

const Item = ({data}) => {
    const {getUnseen} = useQuiz();
    return (
        <div className={"home__box__container__question "+(data.pov.includes('chodce')?'chodec':'ridic')} style={data.img&&{backgroundImage:`url(https://nehodananecisto.cz${data.img})`}}>
            {getUnseen(data.id)&&<SealCheck className="SealCheck" />}
            <span>{data.pov.includes('chodce')?<PersonSimpleWalk />:<Car /> } {data.pov}</span>
            <h3>{ data.name}</h3> 
            <Link to={"/"+data.url+"-"+data.id} >zahrát &rsaquo;</Link>
        </div>
    )
}

export default Item;